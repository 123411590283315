import headImg from "./assets/images/headImg.jpg";
import redBook1 from "./assets/images/redBook-1.jpg";
import redBook2 from "./assets/images/redBook-2.jpg";
import redBook3 from "./assets/images/redBook-3.jpg";
import redBook4 from "./assets/images/redBook-4.jpg";

import wechatQrCode from "./assets/images/wechat-qrcode.jpg";
import lineQrCode from "./assets/images/line-qrcode.jpg";
import menu from "./assets/images/menu.jpg";

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={headImg}></img>
        <div className="text">
          nico和服八阪神社（祇園）
          京都の中心部に位置していて、八坂神社、円山公園、祇園花見小路、知恩院、高台寺、清水寺、八坂の塔、二年坂三年坂、鴨川、錦市場…など人気の観光スポットが周辺に多くあります。
          位于京都市中心，交通四通八达，徒步圈人气景点密集：八阪神社、円山公园祇園花见小路、知恩院、高台寺、清水寺、八板塔、二年坂、三年坂、鴨川、錦市場…
          Located in the center of Kyoto, it has convenient transportation and
          many popular attractions on foot: Yasaka Shrine, Maruyama Park, Gion
          Hanami-koji, Chion-in Temple, Kodai-ji Temple, Kiyomizu-dera Temple,
          Hachiban Pagoda, Ninenban, Sannenban, Kamogawa Nishiki Market…
        </div>
        <div className="red-book-link">
          <a
            href="https://www.xiaohongshu.com/user/profile/64f168420000000006033652?xhsshare=CopyLink&appuid=64f168420000000006033652&apptime=1725925703&share_id=7572667266cf4dc19cec77936755a961"
            target="_blank"
          >
            小红书
          </a>
        </div>
        <img src={redBook1}></img>
        <img src={redBook2}></img>
        <img src={redBook3}></img>
        <img src={redBook4}></img>
        <img src={menu}></img>
        <div className="qrcode">
          <img src={wechatQrCode}></img>
          <img src={lineQrCode}></img>
        </div>
        <div className="text">
          Access 605-0074 京都府京都市東山区祇園町南側532ペンタグラム壱番館T201
          地图
        </div>

        <header>Contact</header>
        <br />
      </header>
    </div>
  );
}

export default App;
